// src/pages/account.js
import React from "react"
//import { Router } from "@reach/router"
import { login, isAuthenticated } from "../utils/auth"
//import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"

/*const Settings = () => <p>Settings</p>
const Billing = () => <p>Billing</p>

const Account = () => {
    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }

    const user = getProfile()

    return (
        <>
            <nav>
                <Link to="/">Home</Link>{" "}
                <Link to="/account/settings/">Settings</Link>{" "}
                <Link to="/account/billing/">Billing</Link>{" "}
                <a
                    href="#logout"
                    onClick={e => {
                        logout()
                        e.preventDefault()
                    }}
                >
                    Log Out
                </a>
            </nav>
            <Router>
                <Home path="/account/" user={user} />
                <Settings path="/account/settings" />
                <Billing path="/account/billing" />
            </Router>
        </>
    )
}

export default Account*/

export default function Account(props) {
    const { accountPage } = props.data

    // Auth0 Authentifizierung
    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }

    return (
        <Layout {...accountPage}>
            {accountPage.blocks.map((block) => {
                const { id, blocktype, ...componentProps } = block
                const Component = sections[blocktype] || Fallback
                return <Component key={id} {...componentProps} />
            })}
        </Layout>
    )
}


export const query = graphql`
  {
    accountPage {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...AboutHeroContent
      }
    }
  }
`
